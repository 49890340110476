import { Layout, Typography } from "antd";
import { Suspense } from "react";
import { Outlet, Link } from "react-router-dom";
import { styled } from "styled-components";
import { Loader } from "./loader";
import { SideBarMenu } from "./sideBar/sideBarMenu";
import { ReactComponent as MySVG } from "../../assets/icons/logo.svg";
import { UserInfo } from "./userInfo/userInfo";

export const ContentLayout = () => {
  const { Header, Sider, Content } = Layout;
  const { Text } = Typography;

  return (
    <Layout>
      <Sider collapsible>
        <StyledWrapper>
          <MySVG />
          <Text
            strong
            type="secondary"
            style={{
              color: "#FFFFFF",
              fontSize: "14px",
            }}
          >
            powered by <Link to={"https://www.in-switch.com/"}>in-switch.com</Link>
          </Text>
        </StyledWrapper>
        <SideBarMenu />
      </Sider>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "84px",
          }}
        >
          <UserInfo />
        </Header>
        <Content>
          <Suspense
            fallback={
              <FallBack>
                <Loader />
              </FallBack>
            }
          >
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

const FallBack = styled.div`
  background-color: rgb(240, 242, 245);
`;

const StyledWrapper = styled.div`
  box-sizing: border-box;
  margin: 18px 14px;
  width: 180px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
