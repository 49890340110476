import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export const authState = (state: RootState) => state.auth;
export const authAlertState = (state: RootState) => state.auth.alerts;
export const userInfoState = (state: RootState) => state.auth.userInfo;
export const authDataState = (state: RootState) => state.auth.authData;

export const authSelector = createSelector(authState, (state) => state);
export const authAlertsSelector = createSelector(authAlertState, (alerts) => alerts);
export const userInfoSelector = createSelector(userInfoState, (userInfo) => userInfo);
export const authDataSelector = createSelector(authDataState, (authData) => authData);
