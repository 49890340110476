import { Avatar, Button, Menu, MenuProps } from "antd";
import { useAppSelector } from "store/store";
import { userInfoState } from "modules/authModule/authSlice/authSelectors";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useLogout } from "common/hooks/useLogout";

type MenuItem = Required<MenuProps>["items"][number];

export const UserInfo = () => {
  const handleLogout = useLogout();
  const userInfo = useAppSelector(userInfoState);
  const items: MenuItem[] = [
    {
      label: (userInfo && userInfo.name) || "-",
      icon: <Avatar shape="square" size={34} icon={<UserOutlined />} />,
      key: "user",
      theme: "light",
      children: [
        {
          key: "setting:1",
          theme: "dark",
          label: (
            <Button size="small" type="dashed" key={"logout_button"} icon={<LogoutOutlined />} onClick={handleLogout}>
              Logout
            </Button>
          ),
        },
      ],
    },
  ];

  return (
    <Menu
      style={{
        display: "flex",
        justifyContent: "flex-end",
        minWidth: "14%",
        width: "100%",
        maxWidth: "45%",
      }}
      theme="dark"
      items={items}
      mode="horizontal"
    />
  );
};
