import { Alert } from "antd";

export type TCustomAlert = {
  type: "success" | "info" | "warning" | "error";
  message: string;
};

export const isCustomAlert = (obj: unknown): obj is TCustomAlert => {
  if (typeof obj === "object" && obj !== null) {
    if (
      "type" in obj &&
      "message" in obj &&
      typeof obj.message === "string" &&
      (obj.type === "success" || obj.type === "info" || obj.type === "warning" || obj.type === "error")
    ) {
      return true;
    }
  }

  return false;
};
