export const routesPaths = {
  base: "/",
  auth: {
    base: "/",
    signIn: "sign-in",
    invitation: "/invitation",
    recoveryPassword: "/recoveryPassword",
    forgot: "/forgot",
    doubleAuth: "/auth",
    doubleSignIn: "/auth/sign-in",
    doubleInvitation: "/auth/invitation",
    doubleRecoveryPassword: "/auth/recoveryPassword",
    doubleForgot: "/auth/forgot",
  },
  admin: {
    base: "/",
    cryptoExchange: "/crypto-exchange",
    paymentProvider: "/payment-provider",
    orderChangeLog: "/order-change-log",
    paymentChangeLog: "/payment-change-log",
    billingReport: "/billing",
  },
  menager: {
    base: "/",
    orders: "orders",
  },
  health: "health",
};
