import { storageNames } from "common/constants/storageNames";
import { TUserInfo, TAuthInfo, TGetUserResponse } from "modules/authModule/types/types";

export const setStorageUserInfo = (remember: boolean, userInfo: TGetUserResponse) => {
  remember
    ? localStorage.setItem(storageNames.localUserInfo, JSON.stringify(userInfo))
    : sessionStorage.setItem(storageNames.sessionUserInfo, JSON.stringify(userInfo));
};

export const setStorageAuth = (remember: boolean, authDataResponse: TAuthInfo) => {
  remember
    ? localStorage.setItem(storageNames.localAuthData, JSON.stringify(authDataResponse))
    : sessionStorage.setItem(storageNames.sessionAuthData, JSON.stringify(authDataResponse));
};

export const getStorageAuth = () => {
  if (!!localStorage.getItem(storageNames.localAuthData)) {
    const authLocal = localStorage.getItem(storageNames.localAuthData);

    return authLocal ? JSON.parse(authLocal) : {};
  } else if (!!sessionStorage.getItem(storageNames.sessionAuthData)) {
    const authSession = sessionStorage.getItem(storageNames.sessionAuthData);

    return authSession ? JSON.parse(authSession) : {};
  } else {
    return {};
  }
};

export const getStorageUserInfo = () => {
  if (!!localStorage.getItem(storageNames.localUserInfo)) {
    const userInfoLocal = localStorage.getItem(storageNames.localUserInfo);

    return userInfoLocal ? JSON.parse(userInfoLocal) : {};
  } else if (!!sessionStorage.getItem(storageNames.sessionUserInfo)) {
    const userInfoSession = sessionStorage.getItem(storageNames.sessionUserInfo);

    return userInfoSession ? JSON.parse(userInfoSession) : {};
  } else {
    return {};
  }
};

export const clearStorageAndCookie = () => {
  // УДАЛЕНИЕ КУКИ
  localStorage.removeItem(storageNames.localUserInfo);
  localStorage.removeItem(storageNames.localAuthData);
  sessionStorage.removeItem(storageNames.sessionUserInfo);
  sessionStorage.removeItem(storageNames.sessionAuthData);
};
