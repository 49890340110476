import { FC, forwardRef, useEffect, useState } from "react";
import { Input, InputProps, Space } from "antd";
import { styled } from "styled-components";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

type TInputWithLabel = InputProps & {
  label?: string;
  labelPlacement?: "top" | "left";
  validationEror?: string;
};

export const InputWithLabel: FC<TInputWithLabel> = forwardRef<HTMLInputElement, TInputWithLabel>((props, ref) => {
  const { label, labelPlacement, validationEror, type, ...rest } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    props.disabled && setPasswordVisible(false);
  }, [props.disabled]);

  return (
    <StyledLabel placement={labelPlacement}>
      {label}
      {type === "password" ? (
        <Space direction="vertical">
          <Input.Password
            {...rest}
            type="password"
            autoComplete="current-password"
            visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Space>
      ) : (
        <Input {...rest} type={type} status={validationEror && "error"} />
      )}
      <ErrorText>{validationEror}</ErrorText>
    </StyledLabel>
  );
});

const StyledLabel = styled.label<{ placement?: "top" | "left" }>`
  font-size: 16px;
  display: flex;
  flex-direction: ${(props) => (props.placement === "left" ? "row" : "column")};
  align-items: ${(props) => (props.placement === "left" ? "center" : "")};
  gap: 4px;
`;

const ErrorText = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.errorRed};
  margin: 0;
  padding: 0;
`;
