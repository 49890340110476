import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const menagerState = (state: RootState) => state.menager;
export const menagerAlertsState = (state: RootState) => state.menager.alerts;

export const ordersSelector = createSelector(menagerState, (state) => {
  return {
    orders: [...state.orders].sort((a, b) => {
      return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
    }),
    loading: state.isLoading,
  };
});
export const menagerAlertsSelector = createSelector(menagerAlertsState, (alerts) => alerts);
