import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Pages from "./pages";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyles, theme } from "common/ui/globalStyles";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { store } from "store/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Pages />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
