import React, { FC, useEffect } from "react";
import { TAlert } from "store/reducers/alertNotifications/types";
import { Alert } from "antd";
import { useAppDispatch } from "store/store";
import { CloseOutlined } from "@ant-design/icons";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

type TProps = {
  alert: TAlert;
  removeActionCreator: ActionCreatorWithPayload<string, string>;
};

export const AlertItem: FC<TProps> = (props) => {
  const { alert, removeActionCreator } = props;
  const dispatch = useAppDispatch();
  useEffect(() => {
    let timeOut: NodeJS.Timeout | undefined;
    if (alert && alert.id) {
      timeOut = setTimeout(() => {
        dispatch(removeActionCreator(alert.id));
      }, 4500);
    }

    return () => timeOut && clearTimeout(timeOut);
  }, [alert]);

  return (
    <Alert
      type={alert.type}
      message={alert.message || alert.description || undefined}
      // description={alert.description || undefined}
      closeIcon={<CloseOutlined />}
      onClose={() => dispatch(removeActionCreator(alert.id))}
    />
  );
};
