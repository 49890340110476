import { message } from "antd";
import { commonAlerts } from "common/constants/commonAlerts";
import { TAlert } from "store/reducers/alertNotifications/types";

const errorAlert: { type: "error"; message: boolean } = {
  type: "error",
  message: false,
};

const successAlert: { type: "success"; message: boolean } = {
  type: "success",
  message: false,
};

const providersSuccessAlerts = {
  providersLoaded: {
    ...successAlert,
    description: "Providers have been loaded.",
  },
  cryptoExchangesLoaded: {
    ...successAlert,
    description: "Crypto exchanges have been loaded.",
  },
  providerSuccessDelete: {
    ...successAlert,
    description: "The provider has been removed.",
  },
  cryptoExchangeSuccessDelete: {
    ...successAlert,
    description: "Crypto exchange has been removed.",
  },
  cryptoExchangeUpdate: {
    ...successAlert,
    description: "Crypto exchange has been updated.",
  },
  cryptoExchangeSuccessAdd: {
    ...successAlert,
    description: "Crypto Exchange has been added.",
  },
  providerSuccessAdd: {
    ...successAlert,
    description: "Provider has been added.",
  },
  providerSuccessUpdate: {
    ...successAlert,
    description: "Provider has been updated.",
  },
  orderStatusSuccessReloaded: {
    ...successAlert,
    description: "Order status changes successfully reloaded.",
  },
  paymentDataStatusSuccessReloaded: {
    ...successAlert,
    description: "Payment data status changes successfully reloaded.",
  },
  operatorSuccessCreated: {
    ...successAlert,
    description: "Operator has been added.",
  },
  operatorSuccessUpdated: {
    ...successAlert,
    description: "Operator has been updated.",
  },
  operatorSuccessDelete: {
    ...successAlert,
    description: "Operator has been removed.",
  },
  invitationSuccessSent: {
    ...successAlert,
    description: "Invitation successfully sent.",
  },
  fileSuccessDownload: {
    ...successAlert,
    description: "File uploaded successfully.",
  },
};

const providersErrorAlerts = {
  noDataError: { ...errorAlert, description: "No data for export." },
  409: {
    ...errorAlert,
    description: "Duplicate operator",
  },
};

export const adminAlerts = { ...commonAlerts, ...providersSuccessAlerts, ...providersErrorAlerts };
