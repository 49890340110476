import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { TSignInForm } from "../types/types";
import { validadePassword } from "../helpers/validators/validatePassword";
import { InputWithLabel } from "common/ui/input/inputWithLabel";
import { useAppDispatch } from "store/store";
import { authorizationUser } from "../authSlice/authThunk";

export const SignInForm = () => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, formState, reset } = useForm<TSignInForm>({
    mode: "all",
  });

  const onSubmit: SubmitHandler<TSignInForm> = async (data) => {
    const { remember, ...rest } = data;
    dispatch(authorizationUser(rest));
  };

  const { errors } = formState;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormItemsWrapper>
        <Controller
          control={control}
          name="login"
          render={({ field }) => (
            <InputWithLabel
              autoComplete="username"
              validationEror={errors.login?.message}
              status={errors.password?.message && "error"}
              placeholder="Login"
              label="Login"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <InputWithLabel
              label="Password"
              {...field}
              type="password"
              placeholder="Password"
              status={errors.password?.message && "error"}
              validationEror={errors.password?.message}
            />
          )}
        />
      </FormItemsWrapper>
      <Button
        style={{
          width: "100%",
        }}
        htmlType="submit"
        type="primary"
      >
        Log in
      </Button>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 10px 18px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
