import { FC, useEffect, useState } from "react";
import { getMenuItemsByRole } from "common/constants/menuListItems";
import { styled } from "styled-components";
import { Menu } from "antd";
import { useAuthAndPolicy } from "common/hooks/useAuthAndPolicy";
import { useLocation } from "react-router-dom";

export const SideBarMenu: FC = () => {
  const { activeRole } = useAuthAndPolicy();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location]);

  return (
    <DesktopSidebar>
      <Menu selectedKeys={[selectedKey]} mode="vertical" theme="dark" items={getMenuItemsByRole(activeRole)} />
    </DesktopSidebar>
  );
};

const DesktopSidebar = styled.div`
  padding: 14px 0;
  display: flex;
  flex-direction: column;
  gap: 2vw;
`;
