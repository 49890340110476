import { createSlice, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";
import { TOrder, TMenagerSlice } from "../types/types";
import { TAlert } from "store/reducers/alertNotifications/types";

const menagerEntityAdapter = createEntityAdapter<TOrder>();

export const menagerSlice = createSlice({
  name: "menager",
  initialState: menagerEntityAdapter.getInitialState<TMenagerSlice>({
    orders: [],
    isLoading: false,
    alerts: [],
  }),
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOrders: (state, action: PayloadAction<TOrder[]>) => {
      state.orders = action.payload;
    },
    setAlerts: (state, action: PayloadAction<TAlert[] | TAlert>) => {
      state.alerts = Array.isArray(action.payload)
        ? [...state.alerts, ...action.payload]
        : [...state.alerts, action.payload];
    },
    removeMenagerAlert: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((item) => item.id !== action.payload);
    },
    removeAllMenagerAlerts: (state) => {
      state.alerts = [];
    },
  },
});

export const { setLoading, setOrders, setAlerts, removeMenagerAlert, removeAllMenagerAlerts } = menagerSlice.actions;
