import { createSlice, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";
import { TAlert, TAlertNotificationsInitialState } from "./types";

export const alertNotificationsAdapter = createEntityAdapter<TAlert>();

export const alertNotificationsSlice = createSlice({
  name: "alertNotifications",
  initialState: alertNotificationsAdapter.getInitialState<TAlertNotificationsInitialState>({
    alerts: [],
    count: 0,
  }),
  reducers: {
    addAlertNotification: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.alerts = [...state.alerts, ...action.payload];
        state.count += action.payload.length;
      } else {
        state.alerts = [...state.alerts, action.payload];
        state.count += 1;
      }
    },
    deleteAlertNotification: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
      state.count = state.alerts.length;
    },
    removeAllAlertNotifications: (state) => {
      (state.alerts = []), (state.count = 0);
    },
  },
});

export const { removeAllAlertNotifications, addAlertNotification, deleteAlertNotification } =
  alertNotificationsSlice.actions;
