import { Loader } from "common/ui/loader";
import React from "react";
import { styled } from "styled-components";

export const LoaderPage = () => {
  return (
    <FallBack>
      <Loader />
    </FallBack>
  );
};

const FallBack = styled.div`
  min-width: 100%;
  min-height: 100%;
  background-color: rgb(240, 242, 245);
`;
