import { get, post, remove, put } from "api";
import { TOperatorForm, TUpdateOperatorRequest } from "../types/operators";
const admin = process.env.REACT_APP_ADMIN;
const pathToEndpoint = `${admin}`;

export const getEmployees = async () => get(`${pathToEndpoint}/Employee/Get/Employee`);

// export const getAllOperators = async () => get(`${pathToEndpoint}/Employee/Get/Employee`);
export const getAllOperators = async () =>
  get(
    `${pathToEndpoint}/odata/Employee?count=true`,
    "application/json;odata.metadata=minimal;odata.streaming=true"
  ).then((res: { ["@odata.context"]: string; ["@odata.count"]: number; value: any }) => {
    return res.value;
  });

export const createOperatorRequest = async (operatorData: TOperatorForm) =>
  post(
    `${pathToEndpoint}/Employee/AddEmployee`,
    JSON.stringify(operatorData),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );

export const removeOperatorRequest = async (operatorId: string) =>
  remove(`${pathToEndpoint}/Employee/DeleteEmployee?employeeId=${operatorId}`);

export const updateOperatorRequest = async (operatorData: TUpdateOperatorRequest) =>
  put(
    `${pathToEndpoint}/Employee/UpdateEmployee`,
    JSON.stringify(operatorData),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );

export const updateInvitationRequest = async (operatorId: string) =>
  put(
    `${pathToEndpoint}/Employee/UpdateInvitation`,
    JSON.stringify(operatorId),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );
