import { get, post } from "api";
import { TAcceptRegistryByTokenRequest } from "../types/types";
const identity = process.env.REACT_APP_IDENTITY;

const pathToAuthEndpoint = `${identity}`;
const ADMIN = process.env.REACT_APP_ADMIN;
const VERSION = process.env.REACT_APP_API_VERSION;

const pathToEndpoint = `${ADMIN}/v${VERSION}`;

export const authorization = async (authDataRequest: string) =>
  post(`${pathToAuthEndpoint}/connect/token`, authDataRequest, `application/x-www-form-urlencoded`);

export const getUserInfo = async (token: string) =>
  get(`${pathToAuthEndpoint}/connect/userinfo`, "application/json", token);

export const acceptRegistryByToken = async (data: TAcceptRegistryByTokenRequest) =>
  post(
    `${pathToEndpoint}/EmployeeActivation/AcceptRegisterByToken`,
    JSON.stringify(data),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );

export const acceptRegisterByTokenRequest = async (data: { password: string; token: string }) =>
  post(
    `${pathToEndpoint}/EmployeeActivation/AcceptRegisterByToken`,
    JSON.stringify(data),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );

export const restoreRegisterByTokenRequest = async (data: { password: string; token: string }) =>
  post(
    `${pathToEndpoint}/EmployeeActivation/RestorePasswordByToken`,
    JSON.stringify(data),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );

export const forgotPasswordRequest = async (email: string) =>
  post(
    `${pathToEndpoint}/EmployeeActivation/Forgot`,
    JSON.stringify(email),
    "application/json;odata.metadata=minimal;odata.streaming=true"
  );
