import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Loader } from "./loader";
import styled from "styled-components";

export const AuthLayout = () => {
  return (
    <Layout style={{ minHeight: "100dvh", backgroundColor: "#8d8c8c" }}>
      <Layout.Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Suspense
          fallback={
            <FallBack>
              <Loader />
            </FallBack>
          }
        >
          <Outlet />
        </Suspense>
      </Layout.Content>
    </Layout>
  );
};

const FallBack = styled.div`
  background-color: rgb(240, 242, 245);
`;
