import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Loader } from "./loader";
import styled from "styled-components";
import { AlertNotifications } from "common/components/alertNotifications/alertNotifications";
import { usePersistRoute } from "common/hooks/usePersistRoute";

export const AppLayout = () => {
  usePersistRoute();

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#8d8c8c",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <AlertNotifications />
      <Suspense
        fallback={
          <FallBack>
            <Loader />
          </FallBack>
        }
      >
        <Outlet />
      </Suspense>
    </div>
  );
};

const FallBack = styled.div`
  background-color: rgb(240, 242, 245);
`;
