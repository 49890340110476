import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const adminState = (state: RootState) => state.admin;
export const adminAlertState = (state: RootState) => state.admin.alerts;

export const adminAlertsSelector = createSelector(adminAlertState, (alerts) => alerts);

export const providersSelector = createSelector(adminState, (state) => {
  return {
    providers: [...state.providers].sort((a, b) => {
      {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }

        return 0;
      }
    }),
    loading: state.isLoading,
  };
});

export const cryptoExchangesSelector = createSelector(adminState, (state) => {
  return {
    cryptoExchanges: [...state.cryptoExchanges].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

      return 0;
    }),
    loading: state.isLoading,
  };
});

export const operatorsSelector = createSelector(adminState, (state) => {
  return {
    operators: [...state.operators].sort((a, b) => {
      return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
    }),
    loading: state.isLoading,
  };
});

export const orderStatusChangeLogSelector = createSelector(adminState, (state) => {
  return {
    orderStatusChangeLog: [...state.ordersChangeLog].sort((a, b) => {
      return new Date(b.updatedUtc).getTime() - new Date(a.updatedUtc).getTime();
    }),
    loading: state.isLoading,
  };
});

export const paymentDataStatusChangeLogSelector = createSelector(adminState, (state) => {
  return {
    paymentDataStatusChangeLog: [...state.adminPaymentDataStatusChangeLog].sort((a, b) => {
      return new Date(b.updatedUtc).getTime() - new Date(a.updatedUtc).getTime();
    }),
    loading: state.isLoading,
  };
});

export const billingReportSelector = createSelector(adminState, (state) => {
  return {
    orders: [...state.orders].sort((a, b) => {
      return new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime();
    }),
    loading: state.isLoading,
  };
});
