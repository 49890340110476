import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AlertItem } from "./alertItem";
import { useAppSelector, useAppDispatch } from "store/store";
import {
  removeAllAlertNotifications,
  deleteAlertNotification,
} from "store/reducers/alertNotifications/alertsNotificationSlice";
import { alertNotificationsState } from "store/reducers/alertNotifications/alertNotificationsSelectors";
import { Button } from "antd";
import { menagerAlertsState } from "modules/menagerModule/menagerSlice/menagerSelectors";
import { removeAllMenagerAlerts, removeMenagerAlert } from "modules/menagerModule/menagerSlice/menagerSlice";
import { adminAlertState } from "modules/adminModule/adminSlice/adminSelectors";
import { removeAdminAlert, removeAllAdminAlerts } from "modules/adminModule/adminSlice/adminSlice";
import { removeAllAuthAlerts, removeAuthAlert } from "modules/authModule/authSlice/authSlice";
import { authAlertState } from "modules/authModule/authSlice/authSelectors";

export const AlertNotifications = () => {
  const [totalAlertsCount, setTotalAlertsCount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const menagerAlerts = useAppSelector(menagerAlertsState);
  const adminAlerts = useAppSelector(adminAlertState);
  const alerts = useAppSelector(alertNotificationsState);
  const authAlerts = useAppSelector(authAlertState);

  useEffect(() => {
    setTotalAlertsCount(menagerAlerts.length + adminAlerts.length + alerts.length + authAlerts.length);
  }, [menagerAlerts, adminAlerts, alerts, authAlerts]);

  const removeAllAlerts = () => {
    dispatch(removeAllAlertNotifications());
    dispatch(removeAllMenagerAlerts());
    dispatch(removeAllAdminAlerts());
    dispatch(removeAllAuthAlerts());
  };

  if (totalAlertsCount > 0) {
    return (
      <Wrapper $visible={totalAlertsCount > 0 ? true : false}>
        <StyledAlertsWrapper $visible={totalAlertsCount > 0 ? true : false}>
          {alerts.map((alert) => (
            <AlertItem key={alert.id} alert={alert} removeActionCreator={deleteAlertNotification} />
          ))}
          {adminAlerts.map((alert) => (
            <AlertItem key={alert.id} alert={alert} removeActionCreator={removeAdminAlert} />
          ))}
          {menagerAlerts.map((alert) => (
            <AlertItem key={alert.id} alert={alert} removeActionCreator={removeMenagerAlert} />
          ))}
          {authAlerts.map((alert) => (
            <AlertItem key={alert.id} alert={alert} removeActionCreator={removeAuthAlert} />
          ))}
        </StyledAlertsWrapper>
        <Button
          type="dashed"
          style={{
            marginTop: "8px",
          }}
          onClick={removeAllAlerts}
        >
          Clear All
        </Button>
      </Wrapper>
    );
  } else {
    return <StyledAlertsWrapper $visible={false} />;
  }
};

type TStyledWrapper = {
  $visible: boolean;
};
const StyledAlertsWrapper = styled.aside<TStyledWrapper>`
  width: 360px;
  height: fit-content;
  max-height: 560px;
  overflow-y: auto;
  display: ${(props) => (props.$visible ? "flex" : "none")};
  flex-direction: column;
  gap: 4px;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }
`;

const Wrapper = styled.div<TStyledWrapper>`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 4%;
  top: 2%;
  z-index: 400;
  display: ${(props) => (props.$visible ? "flex" : "none")};
`;
