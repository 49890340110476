import { combineReducers } from "redux";
import { alertNotificationsSlice } from "./alertNotifications/alertsNotificationSlice";
import { adminSlice } from "modules/adminModule/adminSlice/adminSlice";
import { menagerSlice } from "modules/menagerModule/menagerSlice/menagerSlice";
import { authSlice } from "modules/authModule/authSlice/authSlice";

export const reducers = combineReducers({
  alertsNotifications: alertNotificationsSlice.reducer,
  admin: adminSlice.reducer,
  menager: menagerSlice.reducer,
  auth: authSlice.reducer,
});
