import { Loader } from "common/ui/loader";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";

export const AuthFormsLayout = () => {
  return (
    <StyledSection>
      <Suspense
        fallback={
          <FallBack>
            <Loader />
          </FallBack>
        }
      >
        <Outlet />
      </Suspense>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FallBack = styled.div`
  background-color: rgb(240, 242, 245);
`;
