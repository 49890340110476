import React from "react";
import { SignInForm } from "modules/authModule/components/signInForm";
import { routesPaths } from "common/constants/routePaths";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { Typography } from "antd";

export const SighInPage = () => {
  return (
    <FormWrapper>
      <Typography.Title
        level={3}
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0",
        }}
        title="Sign In"
      >
        Sign In
      </Typography.Title>
      <SignInForm />
      <Link
        style={{
          margin: "0 auto",
        }}
        to={routesPaths.auth.forgot}
      >
        Forgot password?
      </Link>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 18px 12px;
  width: 380px;
  background-color: #ffffff;
`;
