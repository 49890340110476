import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export const HealthPage = () => {
  const [healthDataState, setHealthDataState] = useState<object | undefined>({});

  useEffect(() => {
    const healthData = Sentry.getCurrentScope().getSession()?.toJSON();
    const assemblyEnvironment = process.env.NODE_ENV === "development" ? "stage" : process.env.NODE_ENV;
    const healthDataWithVariableEnvironment = {
      ...healthData,
      attrs: { ...healthData?.attrs, environment: assemblyEnvironment },
    };

    console.log(healthDataWithVariableEnvironment);
    setHealthDataState(healthDataWithVariableEnvironment);
  }, []);

  return <div style={{ backgroundColor: "white" }}>{JSON.stringify(healthDataState)}</div>;
};
