import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    body {
    margin: 0;
    font-family: "Montserrat", 'SF Pro Display', sans-serif;
  }
`;

export const theme = {
  colors: {
    errorRed: "#FF0000",
  },
};
