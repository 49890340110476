import { FC, useEffect } from "react";
import { Card, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import { routesPaths } from "common/constants/routePaths";
import styled from "styled-components";
import { useAppDispatch } from "store/store";
import { useLogout } from "common/hooks/useLogout";
import { getAuthUserInfo } from "modules/authModule/authSlice/authThunk";
import { getStorageAuth } from "common/helpers/storageHandlers";

export const NotFoundPage: FC = () => {
  const dispatch = useAppDispatch();
  const logout = useLogout();

  const checkValidToken = async (token: string) => {
    try {
      await dispatch(getAuthUserInfo({ token })).unwrap();
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    const resetAuthData = getStorageAuth();

    if ("access_token" in resetAuthData) {
      checkValidToken(resetAuthData?.access_token);
    } else {
      logout();
    }
  }, []);

  return (
    <StyledWrapper>
      <StyledCard bordered>
        <Typography.Title
          level={2}
          style={{
            textAlign: "center",
          }}
        >
          Page not found
        </Typography.Title>
        <Divider />
        <StyledParagraf>Sorry, something went wrong. Error: 404</StyledParagraf>
        <StyledParagraf></StyledParagraf>
        <StyledParagraf
          style={{
            flexShrink: "20px",
            textAlign: "center",
          }}
        >
          Back to <Link to={routesPaths.base}>main page</Link>?
        </StyledParagraf>
        <Divider />
      </StyledCard>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  min-height: 100dvh;
  background-color: #8d8c8c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled(Card)`
  width: min(50%, 600px);
  display: flex;
  justify-content: center;
`;

const StyledParagraf = styled(Typography.Paragraph)`
  font-size: 22px;
`;
