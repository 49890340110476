import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { storageNames } from "common/constants/storageNames";

export function usePersistRoute() {
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem(storageNames.currentRoute, location.pathname);
  }, [location]);

  useEffect(() => {
    const savedRoute = sessionStorage.getItem(storageNames.currentRoute);
    if (savedRoute) {
      window.history.pushState({}, "", savedRoute);
    }
  }, []);
}
