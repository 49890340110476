import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAuthState, TAuthInfo, TUserInfo, TGetUserResponse } from "../types/types";
import { TAlert } from "store/reducers/alertNotifications/types";
import { clearStorageAndCookie } from "common/helpers/storageHandlers";

const initialState: TAuthState = {
  isLoading: false,
  alerts: [],
  authData: {
    access_token: "",
    expires_in: 0,
    token_type: "",
    refresh_token: "",
    scope: "",
    id_token: "",
  },
  userInfo: {
    name: "",
    policy: "",
    sub: "",
  },
};

export const authSlice = createSlice({
  name: "authData",
  initialState,
  reducers: {
    clearAuthData: () => initialState,
    updateAuthData: (state, { payload }: PayloadAction<TAuthInfo>) => {
      state.authData = payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addAuthAlerts: (state, action: PayloadAction<TAlert | TAlert[]>) => {
      if (Array.isArray(action.payload)) {
        state.alerts = [...state.alerts, ...action.payload];
      } else {
        state.alerts = [...state.alerts, action.payload];
      }
    },
    removeAuthAlert: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((item) => item.id !== action.payload);
    },
    removeAllAuthAlerts: (state) => {
      state.alerts = [];
    },
    addAuthInfo: (state, action: PayloadAction<TAuthInfo>) => {
      state.authData = action.payload;
    },
    addUserInfo: (state, action: PayloadAction<TGetUserResponse>) => {
      state.userInfo = action.payload;
    },
    logOut: (state) => {
      state.authData = initialState.authData;
      state.userInfo = initialState.userInfo;
      clearStorageAndCookie();
      window.location.reload();
    },
  },
});

export const {
  clearAuthData,
  updateAuthData,
  addAuthAlerts,
  removeAuthAlert,
  removeAllAuthAlerts,
  addAuthInfo,
  addUserInfo,
  logOut,
} = authSlice.actions;
