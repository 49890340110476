import {
  authorization,
  getUserInfo,
  restoreRegisterByTokenRequest,
  acceptRegisterByTokenRequest,
  forgotPasswordRequest,
} from "../api";
import { TAuthInfo, TAuthorization, TGetUserResponse } from "../types/types";
import { buildAuthDataRequest } from "../helpers/buildAuthDataRequest";
import { addAuthAlerts, addAuthInfo, addUserInfo, removeAllAuthAlerts } from "./authSlice";
import { notificationMenager } from "common/helpers/notificationMenager";
import { setStorageAuth, setStorageUserInfo } from "common/helpers/storageHandlers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { authAlerts } from "../constants/authAlerts";

const autorizationHandler = async (authDataRequest: string): Promise<any> => {
  const authDataResponse: TAuthInfo = await authorization(authDataRequest);

  return authDataResponse;
};

export const refreshUser = createAsyncThunk<void, TAuthInfo>("refreshUser", async (dataUser, { dispatch }) => {
  const authDataRequest = buildAuthDataRequest({ refreshToken: dataUser.refresh_token });
  const authDataResponse: TAuthInfo = await autorizationHandler(authDataRequest);
  dispatch(addAuthInfo(authDataResponse));
  dispatch(removeAllAuthAlerts());
  setStorageAuth(false, authDataResponse);
});

export const authorizationUser = createAsyncThunk(
  "authData/authorization",
  async (dataUser: TAuthorization, { dispatch }) => {
    try {
      const authDataRequest = buildAuthDataRequest({ login: dataUser.login, password: dataUser.password });
      const authDataResponse: TAuthInfo = await autorizationHandler(authDataRequest);
      dispatch(getAuthUserInfo({ token: authDataResponse.access_token }));
      setStorageAuth(false, authDataResponse);
      dispatch(addAuthInfo(authDataResponse));
    } catch (error) {
      dispatch(addAuthAlerts(notificationMenager(error, authAlerts)));
    }
  }
);

export const getAuthUserInfo = createAsyncThunk("getUserInfo", async ({ token }: { token: string }, { dispatch }) => {
  const userData: TGetUserResponse = await getUserInfo(token);
  dispatch(addUserInfo(userData));
  setStorageUserInfo(false, userData);

  return userData;
});

export const forgotPasswordThunk = createAsyncThunk(
  "forgotPassword",
  async ({ email }: { email: string }, { dispatch }) => {
    await forgotPasswordRequest(email)
      .then(() => {
        dispatch(addAuthAlerts(notificationMenager("instructionSuccessSend", authAlerts)));
      })
      .catch((error) => dispatch(addAuthAlerts(notificationMenager(error, authAlerts))));
  }
);

export const acceptRegisterByTokenThunk = createAsyncThunk<boolean, { password: string; token: string }>(
  "acceptRegisterByToken",
  async (data, { dispatch }) => {
    try {
      await acceptRegisterByTokenRequest(data);
      dispatch(addAuthAlerts(notificationMenager("registrationCompleted", authAlerts)));

      return true;
    } catch (error) {
      dispatch(addAuthAlerts(notificationMenager(error, authAlerts)));

      return false;
    }
  }
);

export const restorePasswordByTokenThunk = createAsyncThunk<boolean, { password: string; token: string }>(
  "restorePasswordByToken",
  async (data, { dispatch }) => {
    try {
      await restorePasswordByTokenThunk(data);
      dispatch(addAuthAlerts(notificationMenager("restorePasswordSuccess", authAlerts)));

      return true;
    } catch (error) {
      dispatch(addAuthAlerts(notificationMenager(error, authAlerts)));

      return false;
    }
  }
);
