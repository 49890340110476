import React from "react";
import styled from "styled-components";
import { Flex, Spin } from "antd";

export const Loader = () => {
  return (
    <LoaderContainer>
      <Flex align="center" gap="small">
        <Spin size="large" />
      </Flex>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 20%;
  left: calc(50% - 35px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg:first-child {
    width: 70px;
    height: 70px;
    fill: #000000;
  }

  & > svg:last-child {
    position: absolute;
    width: 35px;
    height: 35px;
    top: calc(50% - 17.5px);
    left: calc(50% - 17.5px);
  }
`;
