import { useAppDispatch, useAppSelector } from "store/store";
import { useState, useEffect } from "react";
import { Roles } from "common/enum/role";
import { getStorageAuth, getStorageUserInfo } from "common/helpers/storageHandlers";
import { addUserInfo, addAuthInfo } from "modules/authModule/authSlice/authSlice";
import { userInfoState, authDataState } from "modules/authModule/authSlice/authSelectors";
import { TAuthInfo, TGetUserResponse } from "modules/authModule/types/types";

function isAuthData(obj: any): obj is TAuthInfo {
  return (
    typeof obj === "object" &&
    typeof obj.id_token === "string" &&
    typeof obj.access_token === "string" &&
    typeof obj.expires_in === "number" &&
    typeof obj.token_type === "string" &&
    typeof obj.refresh_token === "string" &&
    typeof obj.scope === "string"
  );
}

const isUserInfo = (data: any): data is TGetUserResponse => {
  if (typeof data === "object" && data !== null) {
    return "policy" in data && typeof data.policy === "string" && "name" in data && typeof data.name === "string";
  } else {
    return false;
  }
};

export const useAuthAndPolicy = () => {
  const [activeRole, setActiveRole] = useState<undefined | Roles>(Roles.LOADER);
  const userInfo = useAppSelector(userInfoState);
  const authData = useAppSelector(authDataState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authData.access_token || authData.access_token === "") {
      const resetAuthData = getStorageAuth();
      if (isAuthData(resetAuthData)) {
        dispatch(addAuthInfo(resetAuthData));
      }
    }
  }, [authData]);

  useEffect(() => {
    if (!userInfo.policy || userInfo.policy === "") {
      const resetUserInfo = getStorageUserInfo();
      if (isUserInfo(resetUserInfo)) {
        dispatch(addUserInfo(resetUserInfo));
      }
    }
  }, [authData]);

  useEffect(() => {
    const role = Object.values(Roles).find((val) => val === userInfo.policy);
    if (role) {
      setActiveRole(role);
    } else {
      setActiveRole(undefined);
    }
  }, [userInfo]);

  return {
    activeRole,
  };
};
