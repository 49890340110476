import { WarningOutlined } from "@ant-design/icons";
import { Card, Divider, Typography } from "antd";
import { routesPaths } from "common/constants/routePaths";
import { FC } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const ErroBoundaryContent: FC = () => {
  return (
    <StyledWrapper>
      <StyledCard bordered>
        <Typography.Title
          level={2}
          style={{
            textAlign: "center",
          }}
        >
          <WarningOutlined /> Something went wrong
        </Typography.Title>
        <Divider />
        <StyledParagraf>Sorry, something went wrong...</StyledParagraf>
        <StyledParagraf></StyledParagraf>
        <StyledParagraf
          style={{
            flexShrink: "20px",
            textAlign: "center",
          }}
        >
          Back to <Link to={routesPaths.base}>main page</Link>?
        </StyledParagraf>
        <Divider />
      </StyledCard>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  min-height: 100dvh;
  background-color: #8d8c8c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled(Card)`
  width: min(50%, 600px);
  display: flex;
  justify-content: center;
`;

const StyledParagraf = styled(Typography.Paragraph)`
  font-size: 22px;
`;
