import { createSlice, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";
import { TAdminState, TOperatorData, TPaymentProvider } from "../types";
import { TAlert } from "store/reducers/alertNotifications/types";
import { TOrderStatusChangeLogItem } from "../types/orderStatusChangeLog";
import { TAdminPaymentDataStatusChangeLogItem } from "../types/paymentChangeLog";
import { TCryptoExchange } from "../types";
import { getAllOperatorsThunk, updateOperatorThunk } from "./asyncThunks/operatorsThunk";
import { OPERATOR_ROLE_ID } from "../constants/constants";
import { notificationMenager } from "common/helpers/notificationMenager";
import { adminAlerts } from "../constants/adminAlerts";
import { TOrder } from "modules/menagerModule/types/types";

const adminEntityAdapter = createEntityAdapter<TPaymentProvider>();

export const adminSlice = createSlice({
  name: "admin",
  initialState: adminEntityAdapter.getInitialState<TAdminState>({
    providers: [],
    operators: [],
    cryptoExchanges: [],
    ordersChangeLog: [],
    adminPaymentDataStatusChangeLog: [],
    isLoading: false,
    alerts: [],
    orders: [],
  }),

  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addProvider: (state, action: PayloadAction<TPaymentProvider>) => {
      state.providers = [...state.providers, action.payload];
    },
    addAllProviders: (state, action: PayloadAction<TPaymentProvider[]>) => {
      state.providers = action.payload;
    },
    addOperators: (state, { payload }: PayloadAction<TOperatorData | TOperatorData[]>) => {
      if (Array.isArray(payload)) {
        state.operators = [...state.operators, ...payload];
      } else {
        state.operators = [...state.operators, payload];
      }
    },
    patchProvider: (state, { payload }: PayloadAction<TPaymentProvider>) => {
      const targetIndex = state.providers.findIndex((item) => item.id === payload.id);
      state.providers[targetIndex] = {
        ...state.providers[targetIndex],
        ...payload,
      };
    },
    addOrderStatusChangeLog: (state, action: PayloadAction<TOrderStatusChangeLogItem[]>) => {
      state.ordersChangeLog = action.payload;
    },
    addCryptoExchanges: (state, action: PayloadAction<TCryptoExchange[] | TCryptoExchange>) => {
      if (!Array.isArray(action.payload)) {
        state.cryptoExchanges = state.cryptoExchanges.concat(action.payload);
      } else {
        state.cryptoExchanges = action.payload;
      }
    },
    setOrders: (state, action: PayloadAction<TOrder[]>) => {
      state.orders = action.payload;
    },
    addPaymentProviderChangeLog: (state, action: PayloadAction<TAdminPaymentDataStatusChangeLogItem[]>) => {
      state.adminPaymentDataStatusChangeLog = action.payload;
    },
    removeProvider: (state, action: PayloadAction<string>) => {
      state.providers = state.providers.filter((provider) => provider.id !== action.payload);
    },
    addAdminAlerts: (state, action: PayloadAction<TAlert[] | TAlert>) => {
      if (Array.isArray(action.payload)) {
        state.alerts = [...state.alerts, ...action.payload];
      } else {
        state.alerts = [...state.alerts, action.payload];
      }
    },
    removeAdminAlert: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((item) => item.id !== action.payload);
    },
    removeCryptoExchange: (state, action: PayloadAction<string>) => {
      state.cryptoExchanges = state.cryptoExchanges.filter((cryptoExchange) => cryptoExchange.id !== action.payload);
    },
    patchCryptoExchange: (state, { payload }: PayloadAction<TCryptoExchange>) => {
      const targetIndex = state.cryptoExchanges.findIndex((item) => item.id === payload.id);
      state.cryptoExchanges[targetIndex] = {
        ...state.cryptoExchanges[targetIndex],
        ...payload,
      };
    },
    removeAllAdminAlerts: (state) => {
      state.alerts = [];
    },
    removeOperatorById: (state, action: PayloadAction<string>) => {
      state.operators = state.operators.filter((operator) => operator.id !== action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllOperatorsThunk.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllOperatorsThunk.fulfilled, (state, action: PayloadAction<TOperatorData[]>) => {
        state.isLoading = false;
        if (action.payload) {
          if (Array.isArray(action.payload)) {
            const operators = action.payload.filter((item) => item.roleId === OPERATOR_ROLE_ID);
            state.operators = operators;
          }
        }
      })
      .addCase(getAllOperatorsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.alerts = [...state.alerts, notificationMenager(action.error, adminAlerts)];
      })
      .addCase(updateOperatorThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOperatorThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateOperatorThunk.fulfilled, (state, action: PayloadAction<TOperatorData>) => {
        if (action.payload && "id" in action.payload) {
          const targetIndex = state.operators.findIndex((item) => item.id === action.payload.id);
          state.operators[targetIndex] = {
            ...state.operators[targetIndex],
            ...action.payload,
          };
          state.alerts = [...state.alerts, notificationMenager("operatorSuccessUpdated", adminAlerts)];
        }
        state.isLoading = false;
      });
  },
});

export const {
  setLoading,
  addProvider,
  addAllProviders,
  removeProvider,
  addAdminAlerts,
  removeAdminAlert,
  addOrderStatusChangeLog,
  addPaymentProviderChangeLog,
  removeAllAdminAlerts,
  addCryptoExchanges,
  removeCryptoExchange,
  patchCryptoExchange,
  patchProvider,
  addOperators,
  removeOperatorById,
  setOrders,
} = adminSlice.actions;
