import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOperatorData, TOperatorForm, TUpdateOperatorRequest } from "modules/adminModule/types/operators";
import { addAdminAlerts, addOperators, setLoading, removeOperatorById } from "../adminSlice";
import {
  createOperatorRequest,
  getAllOperators,
  updateOperatorRequest,
  removeOperatorRequest,
  updateInvitationRequest,
} from "modules/adminModule/api/adminEmploeeApi";
import { notificationMenager } from "common/helpers/notificationMenager";
import { adminAlerts } from "modules/adminModule/constants/adminAlerts";

export const getAllOperatorsThunk = createAsyncThunk<TOperatorData[]>("getAllOperators", async (_, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const result = await getAllOperators();

    return result;
  } catch (error) {
    dispatch(addAdminAlerts(notificationMenager(error, adminAlerts)));
  } finally {
    dispatch(setLoading(false));
  }
});

export const removeOperatorThunk = createAsyncThunk<void, string>(
  "removeOperator",
  async (operatorId, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      removeOperatorRequest(operatorId)
        .then((res) => {
          if (res === true) {
            dispatch(removeOperatorById(operatorId));
            dispatch(addAdminAlerts(notificationMenager("operatorSuccessDelete", adminAlerts)));
          } else {
            dispatch(addAdminAlerts(notificationMenager(res, adminAlerts)));
          }
        })
        .catch((error) => {
          dispatch(addAdminAlerts(notificationMenager(error, adminAlerts)));
        });
    } catch (error) {
      dispatch(addAdminAlerts(notificationMenager(error, adminAlerts)));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const createOperatorThunk = createAsyncThunk<void, TOperatorForm>(
  "createOperator",
  async (data, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      await createOperatorRequest(data)
        .then((res: TOperatorData) => {
          if ("id" in res) {
            dispatch(addAdminAlerts(notificationMenager("operatorSuccessCreated", adminAlerts)));
            dispatch(updateInvitationThunk(res.id));
            dispatch(addOperators(res));
          } else {
            dispatch(addAdminAlerts(notificationMenager(res, adminAlerts)));
          }
        })
        .catch((error) => dispatch(addAdminAlerts(notificationMenager(error, adminAlerts))));
    } catch (error) {
      dispatch(addAdminAlerts(notificationMenager(error, adminAlerts)));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateOperatorThunk = createAsyncThunk<TOperatorData, TUpdateOperatorRequest>(
  "updateOperator",
  async (data, { dispatch }) => {
    try {
      const result = await updateOperatorRequest(data);

      return result;
    } catch (error) {
      dispatch(addAdminAlerts(notificationMenager(error, adminAlerts)));
    }
  }
);

export const updateInvitationThunk = createAsyncThunk<void, string>("updateInvitation", async (id, { dispatch }) => {
  try {
    await updateInvitationRequest(id).then((res) => {
      if (res && "id" in res) {
        dispatch(addAdminAlerts(notificationMenager("invitationSuccessSent", adminAlerts)));
      } else {
        dispatch(addAdminAlerts(notificationMenager(res, adminAlerts)));
      }
    });
  } catch (error) {
    dispatch(addAdminAlerts(notificationMenager(error, adminAlerts)));
  }
});
