export const errorAlert: { type: "error"; message: boolean } = {
  type: "error",
  message: false,
};

export const successAlert: { type: "success"; message: boolean } = {
  type: "success",
  message: false,
};

const successAlerts = {
  commonSuccess: {
    ...successAlert,
    description: `Success!`,
  },
  200: {
    ...successAlert,
    description: `Successfully processed request.`,
  },
};

export const serverErrors = {
  401: {
    ...errorAlert,
    description: `Error: 401`,
  },
  404: {
    ...errorAlert,
    description: `Error: 404 Not found`,
  },
  400: {
    ...errorAlert,
    description: `Error: 400 (Bad Request)`,
  },
  409: {
    ...errorAlert,
    description: `Error: 409 Conflict`,
  },
  500: {
    ...errorAlert,
    description: `Error: 500 (Internal Server Error)`,
  },
  tokenError: {
    ...errorAlert,
    description: `Invalid token`,
  },
  unexpectedError: {
    ...errorAlert,
    description: `Unexpected Error!`,
  },
};

export const commonAlerts = {
  ...serverErrors,
  ...successAlerts,
};
