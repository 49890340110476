import { commonAlerts } from "common/constants/commonAlerts";

const successAlert: { type: "success"; message: boolean } = {
  type: "success",
  message: false,
};

export const authSuccessAlets = {
  instructionSuccessSend: {
    ...successAlert,
    description: "Instructions have been sent.",
  },
  registrationCompleted: {
    ...successAlert,
    description: "Registration successfully completed.",
  },
  restorePasswordSuccess: {
    ...successAlert,
    description: "Password has been successfully updated",
  },
};

const errorAlert: { type: "error"; message: boolean } = {
  type: "error",
  message: false,
};

const authErrorAlerts = {
  400: {
    ...errorAlert,
    description: "Wrong login or password",
  },
};

export const authAlerts = {
  ...commonAlerts,
  ...authSuccessAlets,
  ...authErrorAlerts,
};
