import { routesPaths } from "common/constants/routePaths";
import { logOut } from "modules/authModule/authSlice/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate(routesPaths.base);
    dispatch(logOut());
  };

  return handleLogout;
};
