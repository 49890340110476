import { commonAlerts } from "common/constants/commonAlerts";
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";
import { isCustomAlert } from "./typeGyards/isCustomAlert";
import { isCustomError } from "./typeGyards/isCustomError";

export const notificationMenager = (notification: unknown, alerts: { [key: string | number]: any }) => {
  const getAlertType = (error: unknown) => {
    if (isCustomError(error)) {
      const key = error.status;
      if (key in alerts) {
        return {
          id: generateUniqueID(),
          ...alerts[key],
        };
      } else {
        const { status, bodyError } = error;

        return {
          id: generateUniqueID(),
          type: status > 399 && status < 500 ? "error" : undefined,
          message: `Error: ${status}`,
          description: bodyError && JSON.stringify(bodyError),
        };
      }
    }

    if (typeof error === "object" && error !== null) {
      if ("error" in error) {
        if (typeof error.error === "object" && error.error !== null) {
          let errorMessage = "";
          const { error: bodyError } = error;
          if ("code" in bodyError) {
            errorMessage += `${bodyError.code}: `;
          }
          if ("message" in bodyError) {
            errorMessage += bodyError.message;
          }

          return {
            id: generateUniqueID(),
            type: "error",
            description: errorMessage,
          };
        }
      }

      if ("data" in error) {
        const { data } = error;
        if (typeof data === "object" && data !== null) {
          const errorName = "type" in data ? data.type : "Error:";
          const errorText = Object.values(error).find(
            (value) => typeof value === "string" && value.includes("Exception")
          );

          return {
            id: generateUniqueID(),
            type: "error",
            description: `${errorName} : ${errorText}`,
          };
        }
      }

      if ("status" in error && typeof error.status === "number") {
        const key = `${error.status}`;
        if (key in alerts) {
          return {
            id: generateUniqueID(),
            //@ts-ignore
            ...alerts[key],
          };
        } else {
          return {
            id: generateUniqueID(),
            ...alerts["unexpectedError"],
          };
        }
      }
    }
    if (isCustomAlert(error)) {
      return {
        id: generateUniqueID(),
        ...error,
      };
    }

    if (error instanceof Error) {
      return {
        id: generateUniqueID(),
        type: "error",
        message: `${error.name}: ${error.message}`,
      };
    }

    if (typeof error === "string") {
      if (error in alerts) {
        return {
          id: generateUniqueID(),
          //@ts-ignore
          ...alerts[error],
        };
      } else {
        return {
          id: generateUniqueID(),
          message: error,
        };
      }
    }
    if (Array.isArray(error)) {
      return error.map((err) => {
        return {
          id: generateUniqueID(),
          ...err,
        };
      });
    }

    if (typeof notification === "string" || (typeof notification === "number" && notification in alerts)) {
      return {
        id: generateUniqueID(),
        ...alerts[notification],
      };
    }

    return {
      id: generateUniqueID(),
      ...alerts["unexpectedError"],
    };
  };

  if (Array.isArray(notification)) {
    return notification.map((notif) => {
      return getAlertType(notif);
    });
  } else {
    return getAlertType(notification);
  }
};
