import { routesPaths } from "./routePaths";
import { Roles } from "common/enum/role";
import type { MenuProps } from "antd";
import { NavLink } from "react-router-dom";
import {
  CopyrightOutlined,
  FilePptFilled,
  FileTextFilled,
  TableOutlined,
  TransactionOutlined,
  UserOutlined,
} from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

export const adminMenuListItems: MenuItem[] = [
  {
    label: <NavLink to={routesPaths.admin.base}>Operators</NavLink>,
    key: routesPaths.admin.base,
    icon: <UserOutlined />,
  },
  {
    label: <NavLink to={routesPaths.admin.cryptoExchange}>Crypto Exchange</NavLink>,
    key: routesPaths.admin.cryptoExchange,
    icon: <CopyrightOutlined />,
  },
  {
    label: <NavLink to={routesPaths.admin.paymentProvider}>Payment Provider</NavLink>,
    key: routesPaths.admin.paymentProvider,
    icon: <TransactionOutlined />,
  },
  {
    label: <NavLink to={routesPaths.admin.orderChangeLog}>Order status log</NavLink>,
    key: routesPaths.admin.orderChangeLog,
    icon: <FileTextFilled />,
  },
  {
    label: <NavLink to={routesPaths.admin.paymentChangeLog}>Payment data log</NavLink>,
    key: routesPaths.admin.paymentChangeLog,
    icon: <FilePptFilled />,
  },
  {
    label: <NavLink to={routesPaths.admin.billingReport}>Billing report</NavLink>,
    key: routesPaths.admin.billingReport,
    icon: <TableOutlined />,
  },
];

export const managerMenuListItems: MenuItem[] = [
  {
    label: <NavLink to={routesPaths.menager.base}>Order list</NavLink>,
    key: routesPaths.menager.base,
    icon: <TableOutlined />,
  },
];

export const getMenuItemsByRole = (role?: Roles) => {
  switch (role) {
    case Roles.ADMIN:
      return adminMenuListItems;
    case Roles.MENAGER:
      return managerMenuListItems;
    default:
      return [];
  }
};
